<template>
  <div>
    <validation-observer v-slot="{ handleSubmit }">
      <form
        @submit.prevent="
          !chickenleg
            ? handleSubmit(insertChickenLeg)
            : handleSubmit(updateChickenLeg)
        "
      >
        <b-row>
          <b-col>
            <validation-provider rules="required" v-slot="{ errors, valid }">
              <b-form-group label="Descripción" label-for="description">
                <b-form-input
                  id="description"
                  v-model="chickenLegObject.description"
                  :state="errors[0] ? false : valid ? true : null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider rules="required" v-slot="{ errors, valid }">
              <b-form-group label="Prioridad" label-for="priority">
                <b-form-input
                  id="priority"
                  v-model="chickenLegObject.priority"
                  :state="errors[0] ? false : valid ? true : null"
                />
              </b-form-group>
            </validation-provider>
            <span class="text-info">
              Prioridades ocupadas: <br />
              <span
                v-for="(prioridad, index) in arrayPriorities"
                :key="prioridad.id"
              >
                <strong>{{
                  prioridad.priority +
                  (index != arrayPriorities.length - 1 ? ", " : "")
                }}</strong>
              </span>
            </span>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
            <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                v-if="!busy"
                type="submit"
            >
                {{
                !chickenleg ? "Guardar" : "Actualizar"
                }}
            </b-button>
            <b-button
                variant="primary"
                disabled
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                v-else
            >
                <b-spinner small />
                Cargando...
            </b-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  props: {
    chickenleg: Object,
  },
  data() {
    return {
      chickenLegObject: {
        description: null,
        created_by: null,
        priority: null,
      },
      busy: false,
      arrayPriorities: [],
    };
  },
  mounted() {
    if (this.chickenleg) {
      this.chickenLegObject.description = this.chickenleg.description;
      this.chickenLegObject.priority = this.chickenleg.priority;
    } else {
      this.chickenLegObject.description = null;
      this.chickenLegObject.priority = null;
    }
    this.getPriorities();
  },
  methods: {
    insertChickenLeg() {
      this.busy = true;
      axios
        .post("/api/chickenleg/insert", {
          description: this.chickenLegObject.description,
          created_by: this.currentUser.user_id,
          priority: this.chickenLegObject.priority,
        })
        .then((response) => {
          this.busy = false;
          this.$emit("closeModal", false);
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: err.response.data.errors,
            text: err.response.data.text,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
          this.busy = false;
        });
    },
    updateChickenLeg() {
      this.busy = true;
      axios
        .put(`/api/chickenleg/update/${this.chickenleg.id}`, {
          description: this.chickenLegObject.description,
          created_by: this.currentUser.user_id,
          priority: this.chickenLegObject.priority,
        })
        .then((response) => {
          this.busy = false;
          this.$emit("closeModal", false);
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: err.response.data.errors,
            text: err.response.data.text,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
          this.busy = false;
        });
    },
    getPriorities() {
      axios.get("/api/chickenleg/current-priorities").then((response) => {
        this.arrayPriorities = response.data;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
